<template>
  <div id="lexatel-chat"></div>
</template>

<script>
export default {
  name: 'ChatBot',
  mounted() {
    this.loadScript('/chat_widget/vendor.js')
      .then(() => {
        console.log('Vendor script loaded');
        return this.loadScript('/chat_widget/main.js');
      })
      .then(() => {
        console.log('Main script loaded');
        this.observeBodyStyles();
      })
      .catch(error => {
        console.error('Error loading scripts:', error);
      });
  },
  methods: {
    loadScript(src) {
      return new Promise((resolve, reject) => {
        let script = document.createElement('script');
        script.src = src;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    },
    observeBodyStyles() {
      const observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
            document.body.style.overflow = 'hidden';
          }
        });
      });

      observer.observe(document.body, { attributes: true });
    }
  }
};
</script>
