<template>
  <div class="ClientMain">
    <ReferenceNavBar />

    <div class="Reference">
      <h2 class="NameOfServise">Сервис выдачи электронных справок</h2>
      <div class="ReferenceMainframe">
        <ReferenceTravelPrice />
        <ReferenceSeatAvailable />
        <ReferenceLostDocument />
        <ReferenceShortRoute />
        <ReferenceTrainChange />
        <ReferenceInfo />
      </div>
    </div>
    <div class="Footer">
      <ReferenceFooter />
    </div>
    <ChatBot />
  </div>
</template>

<script>
import helper from '@/helpers/reference/reference.helper';
import ReferenceTravelPrice from '@/components/reference/ReferenceTravelPrice';
import ReferenceLostDocument from '@/components/reference/ReferenceLostDocument';
import ReferenceSeatAvailable from '@/components/reference/ReferenceSeatAvailable';
import ReferenceShortRoute from '@/components/reference/ReferenceShortRoute';
import ReferenceTrainChange from '@/components/reference/ReferenceTrainChange';
import ReferenceFooter from '@/components/reference/ReferenceFooter';
import ReferenceInfo from '@/components/reference/ReferenceInfo';
import ReferenceNavBar from '@/components/reference/ReferenceNavBar';
import ChatBot from '@/components/ChatBot';

import { mapActions } from 'vuex';
export default {
  data() {
    return {
      search: null,
      checkInterval: null
    };
  },
  components: {
    ReferenceTravelPrice,
    ReferenceLostDocument,
    ReferenceSeatAvailable,
    ReferenceShortRoute,
    ReferenceTrainChange,
    ReferenceFooter,
    ReferenceInfo,
    ReferenceNavBar,
    ChatBot
  },
  methods: {
    declOfNum: helper.declOfNum,
    ...mapActions('check', ['clientCheck'])
  }
  // mounted() {
  //   this.clientCheck();
  //   this.checkInterval = setInterval(() => {
  //     try {
  //       this.clientCheck();
  //     } catch (ex) {
  //       console.log(ex);
  //     }
  //   }, 3600000);
  // },
  // beforeDestroy() {
  //   if (this.checkInterval !== null) {
  //     clearInterval(this.checkInterval);
  //   }
  // }
};
</script>

<style scoped>
.ClientMain {
  background-color: rgb(255, 255, 255);
  font-family: RussianRail;
  /* height: 100%; */
}
.Reference {
  border-radius: 10px;
  margin-right: 10%;
  margin-top: 3%;
  margin-bottom: 3%;
  background-color: white;
  padding-bottom: 30px;
  padding-left: 10%;
}
.NameOfServise {
  font-family: FsRail;
  padding-top: 30px;
  text-align: center;
}
/* .chat {
  position: fixed;
  bottom: 1%;
  right: 1%;
} */
.TextMain {
  padding-left: 3%;
}
.text-black input {
  color: black !important;
}
</style>
